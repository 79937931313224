<template>
  <div id="related-link-logs-page">
    <b-card no-body class="h-100">
      <!-- header component -->
      <b-card-header>
        <!-- title -->
        <b-card-title class="d-flex align-items-center">
          <i class="bi bi-link-45deg"></i>
          <span style="margin-left: 5px"> Related Link </span>
        </b-card-title>
        <!-- filter component -->
        <div class="d-flex">
          <b-button variant="outline-success" class="mr-1" @click="exportData">
            <feather-icon icon="FileTextIcon" />
            <span style="margin-left: 2px">Export</span>
          </b-button>
          <!-- keyword filter -->
          <b-input-group class="input-group-merge" style="width: 20vw">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input
              placeholder="Masukkan kata kunci..."
              v-model="text"
              style="height: 3rem"
            />
          </b-input-group>
        </div>
      </b-card-header>
      <!-- loader component -->
      <loader-component v-if="is_loading" />
      <!-- table-component -->
      <table-component
        v-else
        :data="table_data"
        :viewDetail="viewDetail"
        :is_row_clicked="true"
        class="mt-1"
      />
    </b-card>
    <!-- content modal component -->
    <content-modal-component :data="modal_data" :search_item="text" />
  </div>
</template>
<script>
import $ from "jquery";
import useJwt from "@/auth/jwt/useJwt";
import moment from "moment";
import dataTable from "@/views/components/dataTable.vue";
import loader from "@/views/components/loader.vue";
import contentModal from "@/views/components/related-link/contentModal.vue";

import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardSubTitle,
  BCardBody,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BButton,
} from "bootstrap-vue";
import axios from "axios";

export default {
  name: "RelatedLinkLogs",
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BCardSubTitle,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BButton,
    "table-component": dataTable,
    "loader-component": loader,
    "content-modal-component": contentModal,
  },
  data() {
    return {
      is_loading: true,
      from_date: new Date(),
      to_date: new Date(),
      text: "baca juga",
      page: this.$route.query.page ? this.$route.query.page : 1,
      items: this.$route.query.items ? this.$route.query.items : 10,
      table_data: {
        page_info: {
          limit: 10,
          page: 1,
          total: 0,
          _id: null,
        },
        filter: "",
        fields: [
          {
            key: "no",
            label: "no",
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { width: "1rem" },
          },
          {
            key: "account",
            label: "nama akun",
            sortable: true,
            thClass: "text-left",
            tdClass: "text-left",
          },
          {
            key: "title",
            label: "judul",
            thClass: "text-justify",
            tdClass: "text-justify",
          },
          {
            key: "url",
            label: "url",
            thClass: "text-center",
            tdClass: "text-center",
          },
          {
            key: "date",
            label: "tanggal",
            sortable: true,
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { paddingRight: "2rem" },
          },
          {
            key: "timestamp",
            label: "timestamp",
            sortable: true,
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { paddingRight: "2rem" },
          },
        ],
        items: [],
      },
      modal_data: null,
      cancelToken: null,
    };
  },
  created() {
    this.getData();
  },
  watch: {
    "$route.query"() {
      this.page = this.$route.query.page ? this.$route.query.page : 1;
      this.items = this.$route.query.items ? this.$route.query.items : 10;
      this.getData();
    },
    text() {
      if (this.text.length == 0 || this.text.length > 2) {
        this.updateQuery("page", 1);
        this.getData();
      }
    },
  },
  methods: {
    exportData() {
      const params = {
        from_date: moment(this.from_date).format("YYYY-MM-DD 00:00:00"),
        to_date: moment(this.to_date).format("YYYY-MM-DD 23:59:59"),
        text: this.text,
      };
      let query = Object.keys(params)
        .map((key) => key + "=" + params[key])
        .join("&");
      const url = `${process.env.VUE_APP_API_URL}scarper/related-link/export?${query}`;
      window.open(url);
    },
    getData() {
      this.is_loading = true;
      if (this.cancelToken) {
        this.cancelToken.cancel();
      }
      this.cancelToken = axios.CancelToken.source();
      let params = {
        from_date: moment(this.from_date).format("YYYY-MM-DD 00:00:00"),
        to_date: moment(this.to_date).format("YYYY-MM-DD 23:59:59"),
        text: this.text,
        page: this.page,
        items: this.items,
      };
      let query = Object.keys(params)
        .map((key) => key + "=" + params[key])
        .join("&");
      let api = process.env.VUE_APP_API_URL + "scarper/related-link?" + query;
      useJwt.axiosIns
        .get(api, { cancelToken: this.cancelToken.token })
        .then((res) => {
          if (res.status == 200 && res.data.scraper.length > 0) {
            this.table_data.items = res.data.scraper;
            this.table_data.page_info = res.data.page_info[0];
          } else {
            this.table_data.items = [];
            this.table_data.page_info = {
              limit: 10,
              page: 1,
              total: 0,
              _id: null,
            };
            this.is_loading = false;
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          if (this.table_data.items.length > 0) {
            this.is_loading = false;
          }
        });
    },
    updateQuery(key, value) {
      let queries = JSON.parse(JSON.stringify(this.$route.query));
      queries[key] = value;
      if ($.param(this.$route.query) != $.param(queries)) {
        this.$router.replace({ query: queries });
      }
    },
    viewDetail(id) {
      let data = this.table_data.items.filter((el) => el._id == id);
      this.modal_data = data[0];
      this.$bvModal.show("modal-content");
    },
  },
};
</script>
